@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body h1,
p,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
.main_navbar {
  background-color: #fff;
}
.header-logo {
  width: 110px;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.navbar-nav .ss::after {
  content: "|";
}
.navbar-nav {
  gap: 25px;
}
.nave_item li a {
  color: #000;
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  line-height: 29.05px;
  text-align: center;
  border-radius: 12px;
}
.nav-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.nave_item li a.active {
  color: white !important;
  background-color: rgba(187, 160, 108, 0.5) !important;
  border-radius: 12px;
}
/* .nav-item{
  border-radius: 12px;
}
.nav-item:hover{
  color: white !important;
  background-color: #370173;
  border-radius: 12px;
} */
.nave_item li a:hover {
  color: rgb(0, 0, 0);
  background-color: rgba(187, 160, 108, 0.5) !important;
  border-radius: 12px;
}
.navbar li .btn_nav {
  color: rgba(187, 160, 108, 0.5);
  border: 1px solid rgba(187, 160, 108, 0.5) !important;
  border-radius: 12px;
}
.cardbtun {
  background-color: rgba(160, 32, 240, 1);
  color: white;
  border-radius: 100px;
  padding: 13px 25px;
  margin: auto;
  font-weight: 600;
  border: 1px solid rgba(160, 32, 240, 1);
}
.cardbtun:hover {
  background-color: #ffffff00;
  color: rgba(160, 32, 240, 1);
  border-radius: 100px;
  margin: auto;
  border: 1px solid rgba(160, 32, 240, 1);
}
.banner-section {
  background-image: url(../public/assests/images/banner_img.png);
  height: 65vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  margin-top: 97px;
  width: 100%;
}
.hme_bnr_text h1 {
  font-size: 48px;
  /* font-family: "Source Sans Pro", sans-serif; */
  font-family: "DM Serif Text";
  color: white;
  font-weight: 600;
  text-shadow: 0 0 0 transparent;
  line-height: 1.15em;
  text-transform: none;
  letter-spacing: 0;
  text-align: center;
  padding: 15px 0 10px;
  margin-bottom: 10px;
}
.hme_bnr_text h1 span {
  color: #29abe2;
  font-size: 42px;
}
.banner_btn {
  color: white;
  border-radius: 10px;
  margin: auto;
  font-weight: 600;
  border: 1px solid #fff;
}
.banner_btn:hover {
  margin: auto;
  border: 1px solid #fff;
  background-color: white;
  color: #370173;
  transition: 0.3s;
}
.banner_btm {
  background-color: #370173;
  padding: 30px 0px;
  text-align: center;
  color: white;
}
.banner_btm .bannr_h1 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}
.banner_btm h2 {
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
}
.contact_btn {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  border: 2px solid #fff;
  background-color: transparent;
  padding: 12px 30px;
  /* margin-top: 10px; */
}
.contact_btn:hover {
  background-color: white;
  color: #370173;
}
.top_cont_w p {
  font-size: 16px;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}
/*--overview-section-start--*/
.overview_section {
  padding-top: 60px;
  padding-bottom: 60px;
}
.over_text {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 10px;
}
.overview_section .over_h1 {
  /* font-family: "Poppins"; */
  font-family: "DM Serif Text";
  font-size: 40px;
  font-weight: 700;
  line-height: 54.84px;
  text-align: center;
  border-bottom: 4px solid #370173;
  width: fit-content;
  margin-bottom: 25px;
}
.overview_section .over_p1 {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
  color: #000000;
}
.learn_btn {
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #370173;
  background: #370173;
  padding: 13px 35px;
  margin-top: 20px;
}
.learn_btn:hover {
  background-color: transparent;
  border: 1px solid #370173;
  color: #370173;
  transition: 0.5s;
}
.overivew_image img {
  border-radius: 30px;
}
/*--overview-section-end--*/
/*--our_patner_start--*/
.our_patner {
  padding-top: 60px;
  padding-bottom: 60px;
}
.patner_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* text-align: center; */
}
.patner_text h4 {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
}
.patner_text h4 span {
  color: #370173;
}
.patner_text p {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  margin: 30px 0px;
  color: #000000;
}
.patner_text button {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 29.05px;
  text-align: center;
  color: white;
  border: 1px solid #370173;
  background: #370173;
  padding: 10px 30px;
  border-radius: 12px;
}
.patner_text button:hover {
  background-color: transparent;
  border: 1px solid #370173;
  transition: 0.5s;
  color: #370173;
}
.patner_image img {
  border-radius: 30px;
}

/*--our_patner_end--*/
/*--services_section-start--*/
.services_section {
  padding-top: 60px;
  padding-bottom: 60px;
}
.services_text .services_texthh1 {
  color: #000;
  /* font-family: "Poppins"; */
  font-family: "DM Serif Text";
  font-size: 40px;
  font-weight: 700;
  line-height: 54.84px;
  text-align: center;
  border-bottom: 4px solid #370173;
  width: fit-content;
  margin-bottom: 25px;
}
.services_text .services_textpp1 {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: justify;
}
.services_text button {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  border: 2px solid #000;
  padding: 10px 26px;
  border-radius: 10px;
  margin-top: 20px;
}
.services_text button:hover {
  color: #370173;
}
/*--services_section-end--*/
/* ahmed css */
.dollar {
  margin: 10px 0px;
  padding: 30px 0px;
  background-color: #370173;
  color: white;
  text-align: center;
}
.dollar .dolar_img1 {
  position: absolute;
  top: -70px;
  left: 0;
  width: 100px;
}
.dollar .dolar_img2 {
  position: absolute;
  bottom: -70px;
  right: 0;
  width: 100px;
}
.blog {
  background-color: #51bae617;
  padding: 50px 0px;
  position: relative;
}
.blogs .blog_crd .pbld {
  font-size: 16px;
  text-align: left;
  font-weight: 700;
}
.blog_crd .pbld {
  font-size: 16px;
  text-align: left;
  font-weight: 700;
}
.blog_crd img {
  width: 100%;
  height: 220px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blogs .blog_crd img {
  width: 100%;
  height: 220px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog_crd {
  height: 560px;
  box-shadow: 0px 2px 2px 0px #37017380;
  background-color: white;
  border: 1px solid #00000080;
  margin-bottom: 65px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog_crd .blog_crd_btm {
  /* height: 410px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  /* text-align: justify; */
}
.btn_lrn_mor {
  background-color: #370173;
  border: 1px solid #370173;
  padding: 8px 25px;
  border-radius: 26px;
  color: white;
  font-weight: 600;
  font-size: 16px;
}
.btn_lrn_mor:hover {
  background-color: transparent;
  border: 1px solid #370173;
  color: #370173;
}

/* Swiper CSS */

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 24px !important;
}
.swiper-button-prev {
  top: auto !important;
  bottom: 0 !important;
}
.blog_crd {
  width: 100%;
}
.w_card1 {
  background-color: #370173;
  padding: 15px 0px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
}
.w22 {
  background-color: #29abe2;
  padding: 15px 0px;
  text-align: center;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
}
.w_card1 img {
  /* width: 100px; */
  height: 100px;
}

.wcard {
  border: 1px solid gray;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.wcard h4 {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.wcard p {
  font-size: 16px;
  text-align: center;
  width: 90%;
  margin: auto;
}
.how_workd {
  padding: 70px 0px;
}
.footer {
  background-color: #002341;
  padding: 20px 0px;
}
.contenttf p {
  font-size: 16px;
  color: white;
  font-weight: 600;
  margin-bottom: 5px;
}
.img_icon {
  height: 40px;
}

.btm_ftr p {
  font-size: 16px;
  color: white;
  font-weight: 600;
}
.btm_ftr p {
  margin-left: 30px;
}
.privacy-policy {
  padding: 90px 0px;
  background-color: #370173;
  color: white;
  margin-top: 90px;
}
.privacy-content {
  margin-top: 40px;
}
.privacy_content {
  margin-top: 100px;
}
.privacy_content a {
  word-break: break-all;
}
.content-pri p {
  font-size: 16px;
  color: var(--privacy);
  margin-bottom: 1rem;
  text-align: justify;
  word-spacing: -2px;
  text-justify: distribute-all-lines;
  hyphens: auto;
}
.content-pri h6 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 20px;
}
/* modal */
.contactfrm-popup-main .modal-dialog button.close {
  position: absolute;

  top: -22px;

  right: -22px;

  padding: 0;

  width: 3.063rem;

  height: 3.063rem;

  border: solid 5px #1e1e51;

  background: white;

  border-radius: 50%;

  color: #1e1e51;

  text-align: center;

  font-family: initial;

  font-size: 30px;

  opacity: 1;

  line-height: normal;

  z-index: 1;

  text-shadow: 0 0 0;

  cursor: pointer;
}

.contactfrm-popup-main {
  padding: 0 !important;
}
.form-label {
  margin-bottom: 0.5rem;
}
.contactfrm_pop h2 {
  color: #1e1e51;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  font-family: var(--source-sans-pro);
  text-transform: capitalize;
  line-height: 1.5;
  margin-bottom: 20px;
}
.contactfrm_pop label {
  font-weight: 700;
  color: #1e1e51;
}

.contactfrm_pop ul li {
  float: left;

  width: 100%;

  margin-bottom: 15px;
}

.contactfrm_pop ul li label {
  color: #000000;

  font-size: 18px;

  margin-bottom: 8px;

  font-family: var(--source-sans-pro);

  font-weight: 500;

  float: left;

  width: 100%;
}

.contactfrm_pop ul li input {
  float: left;

  width: 100%;

  font-size: 15px;

  padding: 0 10px;

  font-size: 15px;

  border: 1px solid black;

  height: 40px;

  color: black;
}

#submitBtn {
  color: white;
  width: 53%;
  cursor: pointer;
  border-radius: 0;
  margin-top: 5px;
  background-color: #1e1e51;
  text-decoration: none;
  padding: 6px 0px 6px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  border-color: #1e1e51;
  border-width: 2px;
  border-style: solid;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: 0 0 0 transparent;
}
#successPopup .modal-header {
  justify-content: space-between;
}

/* Ahmed */
.services {
  position: relative;
  padding: 60px 0px;
  background: #37017305;
}
.services .srvc_h1 {
  /* font-family: "Poppins"; */
  font-family: "DM Serif Text";
  font-size: 40px;
  font-weight: 700;
  line-height: 54.84px;
  text-align: center;
  border-bottom: 4px solid #370173;
  width: fit-content;
  margin-bottom: 25px;
}
.services .srvc_p1 {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.services .servc_card {
  text-align: center;
  background-color: white;
  box-shadow: 0px 2px 2px 2px #00000080;
  padding: 30px 20px;
  /* width: 310px; */
  height: 345px;
  border-radius: 10px;
  margin-top: 20px;
}
.services .servc_card .srvc_h2 {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  border-bottom: 4px solid #370173;
  width: fit-content;
  color: #370173;
}
.services .servc_card .srvc_p2 {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
}
.services .servc_card .mbb {
  margin-bottom: 20px;
}
.services .srvc_cntnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.swiper-wrapper {
  /* margin: 10px; */
  /* position: relative; */
}
.servc_p2 {
  font-size: 16px;
}
.srvce_sction_img {
  display: flex;
  justify-content: end;
  align-items: center;
}
.srvce_sction_img img {
  width: 90%;
  height: 100%;
}
.swiper {
  /* width: 95%; */
  padding-bottom: 0px !important;
}
.swiper-slide {
  display: flex;
  justify-content: center;
  /* margin: 0px 10px; */
}
.swiper-button-next,
.swiper-button-prev {
  color: #370173 !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 46% !important;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 47% !important;
  top: auto !important;
  bottom: 0px !important;
}
.blogs {
  padding: 60px 0px;
}

.top_ftr .ff {
  max-width: 190px;
  margin-bottom: 10px;
}

.blogs .blog_h1 {
  /* font-family: "Poppins"; */
  font-family: "DM Serif Text";
  font-size: 40px;
  font-weight: 700;
  line-height: 54.84px;
  text-align: center;
  border-bottom: 4px solid #370173;
  margin-bottom: 25px;
}
.blogs .blog_card {
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: white;
  margin-top: 30px;
  height: 627px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding-bottom: 35px;
}
.blogs .blog_card .blg_content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  height: 250px;
}
.blogs .blog_card .blg_content .blog_h2 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}
.blogs .blog_card .blg_content .blog_p1 {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  text-align: justify;
  text-justify: distribute-all-lines;
  color: #000000;
  hyphens: auto;
}
.blogs .blog_card .blg_content .blog_p1 a {
  color: #0d6efd;
  text-decoration: none;
}
.blg_btn {
  background-color: #370173;
  border: 1px solid #370173;
  color: white;
  padding: 8px 30px;
  border-radius: 6px;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  /* margin-top: 12px; */
  text-decoration: none;
}
.blg_btn:hover {
  background-color: transparent;
  border: 1px solid #370173;
  color: #370173;
}
.we_do {
  padding: 60px 0px;
  text-align: center;
  background-color: #37017308;
}
.we_do .we_do_h1 {
  /* font-family: "Poppins"; */
  font-family: "DM Serif Text";
  font-size: 40px;
  font-weight: 700;
  line-height: 54.84px;
  text-align: center;
  border-bottom: 4px solid #370173;
  width: fit-content;
  margin-bottom: 25px;
}
.we_do .dop {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  color: black;
}
.we_do .do_crd {
  border: 3px solid #370173;
  height: 325px;
  padding: 25px 30px;
  margin: 10px 0px;
}
.we_do .do_crd img {
  width: 100px;
  margin-bottom: 20px;
}

.we_do .do_crd p {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
}
.we_do .crdd1 {
  border-bottom: none !important;
}
.we_do .crdd2 {
  border-top: none !important;
}
.we_do .savng_btn {
  background-color: #370173;
  border: 1px solid #370173;
  color: white;
  padding: 10px 40px;
  border-radius: 6px;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-top: 12px;
}
.we_do .savng_btn:hover {
  background-color: transparent;
  color: #370173;
  border: 1px solid #370173;
}
@media (min-width: 1400px) {
  .blg_tp img {
    width: 100%;
  }
  .services .servc_card {
    height: 320px;
  }
}
@media (max-width: 1400px) {
  .blogs .blog_card .blg_content {
    height: 290px;
  }
}

@media (max-width: 1200px) {
  .overview_section .over_h1 {
    font-size: 37px;
  }
  .services .srvc_h1 {
    font-size: 37px;
  }
  .we_do .do_crd {
    height: 350px;
    padding: 25px 10px;
  }
  .patner_image img {
    width: 100%;
  }
  .services .servc_card {
    height: 380px;
    padding: 30px 10px;
  }
  .patner_text h4 {
    font-size: 38px;
  }
  .we_do .we_do_h1 {
    font-size: 37px;
  }
  .services_text .services_texthh1 {
    font-size: 37px;
  }
  .blogs .blog_h1 {
    font-size: 37px;
  }
  .blogs .blog_card .blg_content {
    padding: 20px 10px;
  }
  .blogs .blog_card {
    height: 580px;
    padding-bottom: 30px;
  }
  .blogs .blog_card .blg_content {
    height: 297px;
  }
}

/* End */
@media (min-width: 1400px) {
  .blog_crd {
    height: 520px;
  }
  .privacy-content .blog_crd {
    height: 540px;
  }
  .wcard_content {
    padding: 0px 30px;
  }
}
@media (max-width: 1400px) {
  .wcard_content {
    padding: 0px 25px;
  }
}
@media (max-width: 1200px) {
  .blog_crd .blog_crd_btm {
    height: auto !important;
  }
  .blog_crd {
    height: 525px;
  }
  .privacy-content .blog_crd {
    height: 605px;
  }
}
@media (max-width: 991px) {
  .we_do .do_crd {
    height: 330px;
  }
  .navbar-nav .ss::after {
    content: "";
  }
  .privacy-content .blog_crd {
    height: auto !important;
  }
  .partner_row {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .services .servc_card {
    height: 345px;
  }
  .navbar .nav-item {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 10px;
  }
  .banner-section {
    height: 550px;
  }
  .nave_item {
    gap: 0px !important;
  }
  .overivew_image img {
    margin-top: 20px;
    width: 100%;
  }
  .overivew_image {
    display: flex;
    justify-content: center;
  }
  .banner_btm h2 {
    line-height: 52px;
  }
  .patner_image {
    display: flex;
    justify-content: center;
  }
  .srvce_sction_img {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .we_do .crdd1 {
    border-bottom: 3px solid !important;
  }
  .we_do .crdd2 {
    border-top: 3px solid !important;
  }
  .blogs .blog_card {
    height: 620px;
    padding-bottom: 25px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 44% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 45% !important;
  }
}
@media (max-width: 767px) {
  .we_do {
    padding: 25px 0px;
  }
  .we_do .savng_btn {
    padding: 8px 15px;
    font-size: 18px;
    line-height: normal;
  }
  .contact_btn {
    padding: 8px 15px;
  }
  .services .servc_card {
    height: auto;
  }
  .dollar .dolar_img1 {
    display: none;
  }
  .banner_btn {
    padding: 10px 30px !important;
  }
  .blog .blog_crd .blog_crd_btm {
    padding: 20px 8px;
  }
  .navbar img {
    max-width: 80px !important;
  }
  .dolar_img2 {
    display: none;
  }
  .hme_bnr_text h1 {
    font-size: 32px;
  }
  .top_cont_w p {
    font-size: 16px;
    width: 100%;
  }
  .wcard p {
    font-size: 16px;
  }
  .we_do .do_crd {
    padding: 25px 15px;
  }
  .ldpd {
    font-size: 16px;
  }
  .top_ftr p {
    font-size: 16px;
  }
  .dop {
    width: 91%;
    margin: auto;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .blog_crd {
    height: auto;
    padding-bottom: 20px;
  }

  .we_do {
    padding: 25px 0px;
  }
  .hme_bnr_text h1 span {
    font-size: 32px;
  }
  .banner-section {
    height: 400px;
    /*margin-top: 70px;*/
  }
  .banner_btm h2 {
    font-size: 24px;
    line-height: normal;
    margin: 0px;
  }
  .banner_btm {
    padding: 20px 10px;
  }
  .top_ftr .ff {
    margin-bottom: 15px;
  }
  .btm_ftr p {
    font-size: 16px;
    color: white;
    font-weight: 600;
    margin-left: 0px !important;
  }
  .top_ftr p {
    margin-bottom: 2px;
  }
  .img_icon {
    justify-content: center;
    margin-top: 20px;
  }
  .blg_tp img {
    width: 100%;
  }
  .blogs .blog_card {
    height: auto;
    padding-bottom: 20px;
  }
  .overview_section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .our_patner {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .banner_btm .bannr_h1 {
    font-size: 24px;
    line-height: normal;
  }
  .we_do .do_crd {
    height: auto;
  }
  .services_section {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .patner_text h4 {
    font-size: 32px;
    line-height: 40px;
  }
  .patner_text p {
    font-size: 16px;
    line-height: normal;
    margin: 15px 0px;
  }
  .services_text .services_texthh1 {
    font-size: 32px;
    line-height: normal;
    margin-bottom: 15px;
  }
  .services_text button {
    margin-top: 0px;
  }
  .blg_btn {
    padding: 8px 15px;
    font-size: 18px;
    line-height: normal;
  }
  .overview_section .over_h1 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .learn_btn {
    margin-top: 0px;
  }
  .over_p1 {
    font-size: 16px;
  }
  .services .srvc_h1 {
    font-size: 32px;
    line-height: normal;
    margin-bottom: 15px;
  }
  .we_do .we_do_h1 {
    font-size: 32px;
    line-height: normal;
    margin-bottom: 15px;
  }
  .we_do .dop {
    font-size: 16px;
  }
  .services_text .services_textpp1 {
    font-size: 16px;
    line-height: normal;
  }
  .blogs {
    padding: 25px 0px;
  }
  .blogs .blog_h1 {
    font-size: 32px;
    line-height: normal;
    margin-bottom: 25px;
  }
  .blogs .blog_card .blg_content {
    height: auto;
  }
  .blogs .blog_card .blg_content .blog_h2 {
    font-size: 18px;
    line-height: normal;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 43% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 44% !important;
  }
}
@media (max-width: 575px) {
  .banner_btm .bannr_h1 {
    font-size: 20px;
  }
  .overview_section {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .services {
    padding: 25px 0px;
  }
  .services_text .services_texthh1 {
    font-size: 23px;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 42% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 43% !important;
  }
}
@media (max-width: 440px) {
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 41% !important;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 42% !important;
  }
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #1e1e51) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #1e1e51 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 1200px) {
  .nave_item li a {
    font-size: 15px;
  }
}
